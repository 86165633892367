/* .App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
} */


/* .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} */

.main {
    min-height: 80vh;
}

.banner {
    min-height: 60vh;
}

.margin-btn {
    margin-left: 8px;
}

.profile-pic {
    max-height: 250px;
}

.indicator-item {
    background-color: black !important;
}

.indicators>.active {
    background-color: lightgrey !important;
}

.tag {
    padding-left: "8px" !important;
    padding-right: "8px" !important;
    padding-top: "4px" !important;
    padding-bottom: "4px" !important;
    border-radius: "2px" !important;
    margin-right: "4px" !important;
    font-weight: "bold" !important;
}

.wrapper {
    background-color: black;
    height: 1px;
    margin: 32px 0 0;
    text-align: center;
}

.divider-text {
    position: relative;
    top: -8px;
    padding: 0 15px;
    font-weight: 500;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 1em;
    color: #000000;
    background: #ffffff;
}